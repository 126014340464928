import React from 'react';
import PostShare from '../../components/PostShare/PostShare';
import './addpost.css';

const AddPost = () => {

    return (
        <div className='addpost'>
            <PostShare />
        </div>
    );
}; 

export default AddPost;